<template>
  <div style="width: 100%; overflow: hidden">
    <Row class="k-global-grid" v-if="!showText" :gutter="8">
      <Col v-for="item in menus" :key="item.value" span="8">
        <Card class="k-global-grid-item" :style="{ backgroundColor: item.color }">
          <div @click="handleItemClick(item)">{{ item.text }}</div>
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      menus: [
        { text: '产品中心', to: '/prod-list', icon: 'ios-apps', value: 'product', color: '#2db7f5' },
        { text: '联系我们', to: '/contact', icon: 'ios-call', value: 'about', color: '#19be6b' },
        { text: '关于我们', to: '/about', icon: 'ios-people', value: 'contact', color: '#ff9900' },
      ],
      showText: screen.width > 1080,
    };
  },
  methods: {
    handleItemClick(item) {
      this.$router.push(item.to);
    },
  },
};
</script>

<style lang="less" scoped>
.k-global-grid {
  padding: 0 20px;
}
.k-global-grid-item {
  text-align: center;
  color: #fefefe;
  font-weight: bold;
}
</style>
