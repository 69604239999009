import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: '首页' },
  },
  {
    path: '/prod-list',
    name: 'Prod',
    component: () => import('@/views/products/index.vue'),
    meta: { title: '产品中心' },
  },
  {
    path: '/prod-detail',
    name: 'Prod',
    component: () => import('@/views/products/prod-detail.vue'),
    meta: { title: '产品详情' },
    props: (route) => ({ id: route.query.id }),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/contact/index.vue'),
    meta: { title: '联系我们' },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    // meta: { title: '登陆', icon: null, pin: false, cache: false, hidden: true },
    meta: { title: '关于我们' },
  },
  {
    path: '/admin/pms',
    name: 'Pms',
    component: () => import('@/views/admin/pms/index.vue'),
    meta: { title: '商品列表' },
  },
  {
    path: '/admin/pms/add',
    name: 'pms-add',
    component: () => import('@/views/admin/pms/detail.vue'),
    meta: { title: '商品添加' },
  },
  {
    path: '/admin/pms/detail',
    name: 'pms-detail',
    component: () => import('@/views/admin/pms/detail.vue'),
    meta: { title: '商品详情' },
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: './',
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('setRouterName', { routerName: to?.meta?.title || '首页' });
  store.commit('setRouterUrl', { routerUrl: to.fullPath || '/' });
  next();
});

export default router;
