const state = {
  prodData: [
    {
      iid: 0,
      title: '成测机',
      img: 'http://minio.arthaskj.cn:6503/dev/ccj.png',
      content:
        '适用于金属薄膜电容器最终检验性能测试，用于金属外壳，引出端子数为2个或3个的交流电容器，端子为硬插片式，外壳为圆形，底部有螺栓或无螺栓均可。',
      params:
        '产品外形尺寸Φ20-63mm、高度H50-120mm(可按客户要求)\n' +
        '\n' +
        '容量范围 1-100uF   (可按客户要求)\n' +
        '\n' +
        '端子耐压测试电压T-T：AC0-1200V\n' +
        '\n' +
        '端子与外壳测试电压T-C:AC100-5KV、电流0.1-10mA漏电流分选\n' +
        '\n' +
        '容量测试：C-D：0.01pf~99999uf;  tg&:0.0001~9.999\n' +
        '\n' +
        '空气压力:0.5-0.8Mpa\n' +
        '\n' +
        '测试效率：900只/小时\n' +
        '\n' +
        '控制系统：PLC、触摸屏\n' +
        '\n' +
        '设备电源：220V/50Hz\n' +
        '\n' +
        '绝缘电阻测试：100K~5TΩ电压100v',
      flow:
        '1、  手动上料\n' +
        '\n' +
        '2、  交流耐压测试\n' +
        '\n' +
        '3、  极壳测试\n' +
        '\n' +
        '4、 绝缘测试\n' +
        '\n' +
        '5、  容量分选\n' +
        '\n' +
        '6、  合格\n' +
        '\n' +
        '7、  不合格',
    },
    {
      iid: 2,
      title: 'PFC测试机',
      img: 'http://minio.arthaskj.cn:6503/dev/pfccsj.jpg',
      content: 'T50电力电容检测机概要：应用于三相圆形电力电容器的成品测试，保护功能齐全，故障提醒，操作简易性能稳定，人机界面控制。',
      params:
        '1、设备电源：380VAC三相四线/50Hz\n' +
        '\n' +
        '2、功率：     30KW\n' +
        '\n' +
        '3、测试最大容量：3x350μf\n' +
        '\n' +
        '4、测试电压：0~1500VAC\n' +
        '\n' +
        '5、测试时间：1S～100S可调\n' +
        '\n' +
        '6、极壳耐压： AC0～5KV   0.1～20mA\n' +
        '\n' +
        '7、合格与不合格通过指示灯显示\n' +
        '\n' +
        '8、控制系统：PLC+触摸屏\n' +
        '\n' +
        '9、气源：0.4Mpa～0.6Mpa',
      flow: '1、  手动上料\n' + '\n' + '2、  交流耐压测试\n' + '\n' + '3、  极壳测试',
    },
    {
      iid: 3,
      title: '喷金机',
      img: 'http://minio.arthaskj.cn:6503/dev/pjj.png',
      content:
        'XL304喷金机：概要说明：本喷金机适用于金属化薄膜电容器芯子的表面金属喷涂，采用4枪（2枪分别置于2喷涂室内）电容芯体挂在中间链带上做流水输送，喷枪上下垂直扫描，芯体一次性完成喷涂作业。',
      params:
        '喷金材料：锌/锌锡合金       \n' +
        '材料直径：1.5~2.0mm\n' +
        '喷枪数量：4只\n' +
        '喷枪移动速度：最大  200mm/s\n' +
        '喷金距离:100mm-280mm\n' +
        '喷金框尺寸：390mm-450mm\n' +
        '喷金框移动速度：可调、最大4mm/s\n' +
        '送丝速度：可调、最大5m/min\n' +
        '生产效力：（芯体直径50mm）600/h\n' +
        '控制系统：触摸屏+PLC\n' +
        '设备电源：380三相四线50Hz\n' +
        '设备功率：30KW\n' +
        '空气压力：0.5-0.8mpa',
      flow:
        '电容装框，装载上料\n' + '喷金前空气清洁芯子第1端面\n' + '电容第1面喷金\n' + '喷金前空气清洁芯子第2端面\n' + '电容第2面喷金\n' + '结束，下料',
    },
    {
      iid: 4,
      title: 'PFC赋能机',
      img: 'http://minio.arthaskj.cn:6503/dev/pfcfnj.gif',
      content: '适用于电力金属化薄膜电容芯体检测，AC低压清除短路点，正反直流测试，AC高压测试，电流上限分选，容量分选、人机界面控制。',
      params:
        '1、设备电源：220VAC/50Hz\n' +
        '\n' +
        '2、  功率：    10KW\n' +
        '\n' +
        '3、  容量范围：1～350μF\n' +
        '\n' +
        '4、适用芯子尺寸:ø50～ø120 mm,高度：50～150 mm\n' +
        '\n' +
        '5、机器结构：16工位（可根据客户要求）\n' +
        '\n' +
        '6、生产效率：约600只/小时\n' +
        '\n' +
        '7、电容测试频率：1KHz\n' +
        '\n' +
        '8、控制系统：PLC+触摸屏\n' +
        '\n' +
        '9、直流赋能电压:0～±2500VDC可调，\n' +
        '\n' +
        '10、高压交流赋能：0～1000VAC可调\n' +
        '\n' +
        '11、气源：0.4Mpa～0.6Mpa',
      flow:
        '1、  人工上料\n' +
        '\n' +
        '2、  AC电压清除短路点\n' +
        '\n' +
        '3、  正向DC测试\n' +
        '\n' +
        '4、  反向DC测试\n' +
        '\n' +
        '5、  AC高压测试\n' +
        '\n' +
        '6、  容量测试\n' +
        '\n' +
        '7、  合格\n' +
        '\n' +
        '8、  不合格',
    },
    {
      iid: 5,
      title: '赋能机',
      img: 'http://minio.arthaskj.cn:6503/dev/fnj.png',
      content: '自动赋能机产品概述：适用于金属化薄膜电容芯体检测，AC低压清除短路点，直流电压测试，AC高压测试，电流上限分选，容量分选、人机界面控制。',
      params:
        '1、设备电源：220VAC/50Hz                                         \n' +
        '\n' +
        '2、容量范围：1～50μF                                        \n' +
        '\n' +
        '3、适用芯子尺寸: 15～50 mm                            \n' +
        '\n' +
        '4、机器结构：12工位（可根据客户要求）           \n' +
        '\n' +
        '5、生产效率：约900只/小时                 \n' +
        '\n' +
        '6、电容测试频率：1KHz                           \n' +
        '\n' +
        '8、控制系统：PLC+触摸屏                        \n' +
        '\n' +
        '9、直流赋能电压:0～±2000VDC可调，             \n' +
        '\n' +
        '10、高压交流赋能：0～1000VAC可调               \n' +
        '\n' +
        '11、气源：0.4Mpa～0.6Mpa     ',
      flow:
        '1、自动上料                                                 \n' +
        '\n' +
        '  2、AC电压清除短路点      \n' +
        '\n' +
        '  3、电阻放电  \n' +
        '\n' +
        ' 4、正向DC测试0~1200v\n' +
        '\n' +
        ' 5、电阻放电\n' +
        '\n' +
        '  6、短路放电\n' +
        '\n' +
        ' 7、AC高压测试\n' +
        '\n' +
        ' 8、电阻放电\n' +
        '\n' +
        ' 9、短路放电\n' +
        '\n' +
        ' 10、容量测试\n' +
        '\n' +
        ' 11、合格\n' +
        '\n' +
        ' 12、不合格',
    },
    {
      iid: 6,
      title: '注油机',
      img: 'http://minio.arthaskj.cn:6503/dev/zyj.png',
      content: '注油机简介',
      params: '',
      flow: '',
    },
    {
      iid: 7,
      title: '直流充放电',
      img: 'http://minio.arthaskj.cn:6503/dev/zlcfd.jpg',
      content: '直流充放电简介',
      params: '',
      flow: '',
    },
    {
      iid: 8,
      title: '数控',
      img: 'http://minio.arthaskj.cn:6503/dev/skj.jpg',
      content: '数控简介',
      params: '',
      flow: '',
    },
    {
      iid: 9,
      title: 'PFC耐久试验机',
      img: 'http://minio.arthaskj.cn:6503/dev/pfcljsyj.jpg',
      content:
        'W310电力电容耐久机概要：用于电力成品电容老化寿命试验，施加高于电容额定电压值的电压进行测试，将被测产品至于恒温试验箱内，接上试验电源，具有连续老化试验及间隙试验、试验保护功能安全可靠，人机界面控制。',
      params:
        '1、  设备电源：380VAC三相四线/50Hz\n' +
        '\n' +
        '2、  功率：    20KW\n' +
        '\n' +
        '3、  试验电压：0~1500VAC\n' +
        '\n' +
        '4、  试验方法：连续试验与间隙试验可选，测试时间、故障报警记录。\n' +
        '\n' +
        '5、  控制方式：PLC+触摸屏\n' +
        '\n' +
        '6、  产品测试范围：3×1~750uf',
      flow: '间隙试验：通电时间、断电时间设置、测试次数的相关设定。\n' + '\n' + '连续试验：连续试验的时间设置。',
    },
    {
      iid: 10,
      title: '耐久试验机',
      img: 'http://minio.arthaskj.cn:6503/dev/ljsyj.png',
      content:
        '适用于CBB电容器的寿命测试，将产品置入恒温箱内，施加电容额定的一定电压下进行运行，并记录运行时间，具有产品断路、短路报警保护并记录，人机界面控制。',
      params:
        '1、  设备电源：380VAC/50Hz\n' +
        '\n' +
        '2、  功率：    15KW\n' +
        '\n' +
        '3、试验电压：0～750V 连续可调，\n' +
        '\n' +
        '4、试验时间：0～9999.9H 可预置                  \n' +
        '\n' +
        '5、测试电容容量：1~2100uf(可按客户要求)\n' +
        '\n' +
        '6、电流设定：0~500A\n' +
        '\n' +
        '7、采用PLC,触摸屏控制、故障报警显示。',
      flow: '1、设定试验时间0~9999.9H',
    },
    {
      iid: 11,
      title: '铝箔下料机',
      img: 'http://minio.arthaskj.cn:6503/dev/lbxlj.jpg',
      content: '适应于铝箔、纸、塑料膜的定长裁剪，采用伺服电机控制所要裁剪的产品长度，具有裁切精度高，长度设置方便等优点。',
      params: '1、材料厚度：0.2～1mm（可根据客户要求）\n' + '2、裁剪长度：客户设定\n' + '3、裁剪宽度：据客户要求\n' + '4、空气压力：0.5Mpa',
    },
    {
      iid: 12,
      title: '原厂配件3',
      img: 'http://minio.arthaskj.cn:6503/dev/ycpj3.jpg',
      content: '原厂配件3',
      images: ['http://minio.arthaskj.cn:6503/dev/sb1.jpg', 'http://minio.arthaskj.cn:6503/dev/sb2.jpg'],
    },
    {
      iid: 13,
      title: '原厂配件4',
      img: 'http://minio.arthaskj.cn:6503/dev/ycpj4.jpg',
      content: '原厂配件4',
      images: ['http://minio.arthaskj.cn:6503/dev/sb1.jpg', 'http://minio.arthaskj.cn:6503/dev/sb2.jpg'],
    },
  ],
};

const getters = {
  prodObj(state: any) {
    const obj: any = {};
    state.prodData.forEach((x: any) => {
      obj[x.iid] = x;
    });
    return obj;
  },
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
