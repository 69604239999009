<template>
  <div class="container" :style="{ height: '100%' }">
    <home-page></home-page>
  </div>
</template>
<script>
import HomePage from './home/home';
export default {
  data() {
    return {
      isCollapsed: false,
    };
  },
  components: {
    HomePage,
  },
};
</script>

<style scoped>
.container {
  height: 100%;
}
</style>
