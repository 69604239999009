import Vue from 'vue';
import Vuex from 'vuex';
import home from './modules/home';
import prod from './modules/prod';

Vue.use(Vuex);

interface IState {
  theme: string;
  routerName: string;
  routerUrl: string;
}

const state = {
  theme: 'light', // 系统主题
  routerName: '首页',
  routerUrl: '/home',
};

const mutations = {
  setTheme(state: IState, { theme }: { theme: string }) {
    state.theme = theme;
  },
  setRouterName(state: IState, { routerName }: { routerName: string }) {
    state.routerName = routerName;
  },
  setRouterUrl(state: IState, { routerUrl }: { routerUrl: string }) {
    state.routerUrl = routerUrl;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    home,
    prod,
  },
});
