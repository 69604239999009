<template>
  <Tabs type="card">
    <TabPane label="电容器生产类设备">
      <c-production :cards="this.prodData.filter((x) => x.iid <= 8)"></c-production>
    </TabPane>
    <TabPane label="电容器实验类设备">
      <c-production :cards="this.prodData.filter((x) => x.iid >= 9 && x.iid <= 10)"></c-production>
    </TabPane>
    <TabPane label="其他设备">
      <c-production :cards="this.prodData.filter((x) => x.iid >= 11 && x.iid <= 13)"></c-production>
    </TabPane>
  </Tabs>
</template>

<script>
import cProduction from './prod-list.vue';
import { mapState } from 'vuex';

export default {
  name: 'prod-box',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      prodData: (state) => state.prod.prodData,
    }),
  },
  components: {
    cProduction,
  },
};
</script>

<style scoped lang="less"></style>
