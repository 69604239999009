import { IJSONLikeObject } from './interface';

export const getType = (value: any) => Object.prototype.toString.call(value).slice(8, -1);
export const isArray = (value: any) => Array.isArray(value);
export const isObject = (value: any) => getType(value) === 'Object';

export function objToStr(obj: IJSONLikeObject) {
  return Object.entries(obj)
    .reduce((res: string[], [key, value]) => {
      res.push(`${key}=${value}`);
      return res;
    }, [])
    .join('&');
}

export function filterUndefinedNull(obj: { [propName: string]: any }) {
  if (!isObject(obj)) return obj;

  const res: { [propName: string]: any } = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined || obj[key] === null) return;

    res[key] = obj[key];
  });

  return res;
}

export const setData2LocalStorage = (key: string, data: any) => {
  window.localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key: string) => {
  return window.localStorage.getItem(key);
};
