<template>
  <Menu mode="horizontal" theme="dark" :active-name="activeName">
    <Row :gutter="16">
      <Col :xs="12" :sm="12" :md="12" :lg="12" span="12">
        <div class="layout-logo" @click="handleSkipHomePage">{{ showText ? '宁国市柯美自动化设备制造有限公司' : '柯美自动化' }}</div>
      </Col>
      <Col :xs="12" :sm="12" :md="12" :lg="12" span="12">
        <div v-if="showText" class="layout-nav">
          <MenuItem v-for="item in menus" :name="item.value" :key="item.value" :to="item.to">
            <Icon size="20" :type="item.icon" :title="item.text"></Icon>
            <span v-if="showText">{{ item.text }}</span>
          </MenuItem>
        </div>
      </Col>
    </Row>
  </Menu>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'home',
      menus: [
        { text: '首页', to: '/', icon: 'ios-home', value: 'home' },
        { text: '产品中心', to: '/prod-list', icon: 'ios-apps', value: 'product' },
        { text: '联系我们', to: '/contact', icon: 'ios-call', value: 'about' },
        { text: '关于我们', to: '/about', icon: 'ios-people', value: 'contact' },
      ],
      showText: screen.width > 1080,
    };
  },
  mounted() {
    console.log(screen.width);
  },
  methods: {
    handleSkipHomePage() {
      this.$router.push('/');
      this.activeName = 'home';
    },
    handleMenuSelect(name) {
      this.activeName = name;
    },
  },
};
</script>

<style lang="less" scoped>
.layout-logo {
  width: 100%;
  height: 40px;
  float: left;
  position: relative;
  color: #fefefe;
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.layout-nav {
  width: auto;
  position: absolute;
  right: 20px;
}
</style>
