import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ViewUI from 'view-design';
// @ts-ignore
import VueAMap from 'vue-amap';
import './style/main.css';
import 'view-design/dist/styles/iview.css';

import config from './config';
// @ts-ignore
// import i18n from './i18n';

Vue.use(ViewUI);
Vue.use(VueAMap);

// @ts-ignore
// Vue.locale = () => {} // ivew组件国际化不可缺少的部分

VueAMap.initAMapApiLoader({
  // 高德的key
  key: '4095ef9719675381b44e662e43ce8fa1',
  // 插件集合
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.4',
});

Vue.config.productionTip = false;

Vue.prototype.GLOBAL = config;

new Vue({
  router,
  store,
  // @ts-ignore
  // i18n,
  render: (h) => h(App),
}).$mount('#app');
