const prefix = '';
const env = process.env.NODE_ENV || 'production';

const config = {
  env,
  // 部署机制所致，添加个路由前缀，前面斜杠必须，后面不可加反斜杠
  // 此变量将来不需要的时候会直接改为空字符串(“”)或者逐文件删除，代码中不会对此变量容错
  routerPrefix: prefix,
  BASE_URL: '',
  UPLOAD_URL: 'http://localhost:7001',
};

export default config;
