<template>
  <div class="prod-list-contain">
    <Row :gutter="16" class="prod-list-row">
      <Col :xs="24" :sm="24" :md="12" :lg="8" v-for="item in cards" :key="item.iid" span="8">
        <div class="prod-list-item" @click="() => handleClick(item)">
          <prod-card :title="item.title" :img="item.img" :content="item.content"></prod-card>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import ProdCard from './prod-card.vue';
import { GetAllList } from './apis/index';

export default {
  name: 'prod-list',
  components: {
    ProdCard,
  },
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      GetAllList().then((res) => {
        console.log(res);
      });
    },
    handleClick(item) {
      this.$router.push(`/prod-detail?id=${item.iid}`);
    },
  },
};
</script>

<style lang="less" scoped>
.prod-list-contain {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .prod-list-row {
    width: 100%;
  }
  .prod-list-item {
    margin: 10px;
    display: inline-block;
    width: 100%;
  }
}
</style>
