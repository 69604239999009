<template>
  <Card class="prod-card">
    <div class="prod-card-image">
      <img :src="img" width="100%" height="100%" />
    </div>
    <div class="prod-card-contain">
      <h3>{{ title }}</h3>
      <div>
        {{ content }}
      </div>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'prod-card',
  props: {
    title: String,
    img: String,
    content: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.prod-card {
  width: 100%;
  height: 380px;
}
.prod-card-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  text-align: center;
}
.prod-card-contain {
  margin-top: 20px;
  text-align: left;
  h3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  > div {
    height: 90px;
    margin-top: 10px;
    overflow-y: auto;
    color: #999;
  }
}
</style>
