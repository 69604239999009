<template>
  <div class="k-container">
    <global-carousel class="k-margin-bottom-20"></global-carousel>
    <Divider orientation="left"><h2>首页</h2></Divider>
    <prod-box></prod-box>
    <about-box></about-box>
    <contact-box></contact-box>
  </div>
</template>
<script>
import ProdBox from '../products/prod-box.vue';
import ContactBox from '../contact/index.vue';
import AboutBox from '../About.vue';
import GlobalCarousel from '@/components/GlobalCarousel/GlobalCarousel';

export default {
  data() {
    return {};
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
  },
  components: {
    ProdBox,
    ContactBox,
    AboutBox,
    GlobalCarousel,
  },
  methods: {},
};
</script>
<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: all 0.5s;
}

.dark-theme {
  background-color: #2c3e50;
  color: #fafafa;
}

.light-theme {
  background-color: #fff;
  color: #2c3e50;
}

.theme-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  /*display: none;*/
}
.baberrage-stage {
  right: 0;
}
</style>
