<template>
  <div class="k-breadcrumb">
    <a class="k-breadcrumb-item ivu-breadcrumb h-font-weight" @click="$router.back()">返回</a>
    <b class="k-breadcrumb-bar"> | </b>
    <Breadcrumb class="k-breadcrumb-item">
      <BreadcrumbItem class="h-font-weight" to="/">主页</BreadcrumbItem>
      <BreadcrumbItem :to="routerUrl">{{ routerName }}</BreadcrumbItem>
    </Breadcrumb>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'GlobalBreadcrumb',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      routerName: 'routerName',
      routerUrl: 'routerUrl',
    }),
  },
};
</script>

<style lang="less" scoped>
.k-breadcrumb {
  padding-left: 10px;
  .k-breadcrumb-item {
    display: inline-block;
  }
}
.k-breadcrumb-item.ivu-breadcrumb {
  cursor: pointer;
  color: #515a6e;
}
.k-breadcrumb-bar {
  margin: 0 5px;
  color: #dcdee2;
}
</style>
