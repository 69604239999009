<template>
  <div class="k-container">
    <Divider orientation="left"><h2>联系我们</h2></Divider>
    <div class="text-content">
      <Row :gutter="16">
        <Col :xs="24" :sm="24" :md="12" :lg="12" span="12">
          <p>欢迎您访问本公司官网，如有问题请通过以下方式联系我们，我们将会给您最真诚的服务！</p>
          <div><Icon size="20" type="ios-call" />电话：13865300917</div>
          <div><Icon size="20" type="ios-mail" />邮箱：ningguokemei@163com</div>
          <div><Icon size="20" type="ios-navigate" />地址：宁国市经济技术开发区众益广场8幢101号</div>
          <div>
            <Icon size="20" type="ios-chatbubbles" />微信：<img width="120px" height="100px" src="http://minio.arthaskj.cn:6503/dev/wechat.png" />
          </div>
        </Col>
        <Col :xs="24" :sm="24" :md="12" :lg="12" span="12">
          <div style="width: 100%; height: 300px">
            <el-amap @click="handleClick" vid="amapDemo" :bubble="true" :zoom="zoom" :center="center">
              <el-amap-marker :position="[119.034127, 30.649705]" />
            </el-amap>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      zoom: 15,
      center: [119.034127, 30.649705],
    };
  },
  methods: {
    handleClick(e) {
      console.log(e);
    },
  },
};
</script>

<style scoped lang="less">
.text-content {
  padding: 20px;
  P {
    margin-bottom: 20px;
  }
}
</style>
