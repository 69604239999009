// import Cookies from 'js-cookie';
import axios, { AxiosResponse, AxiosError } from 'axios';

// import { statusTextMap } from './index';
// import baseUrls from '../config/base-urls';
// import { ApiError } from './errors';
import { IJSONLikeObject, IRequestConfig } from './interface';
import { objToStr } from './functions';

export { axios };

const client = axios.create({
  // baseURL: 'http://arthaskj.cn',
  timeout: 30000,
  paramsSerializer: (params: IJSONLikeObject) => objToStr(params),
  withCredentials: false,
});

const request = (config: IRequestConfig) => {
  return client
    .request(config)
    .then((response: AxiosResponse) => {
      const { data, message, success } = response.data;
      if (success) {
        return data;
      } else if (response.status === 200) {
        return {
          success: true,
          data: response.data,
        };
      } else {
        console.error(message);
      }
      // return response.data;
    })
    .catch((error: AxiosError) => {
      // console.log`%c${JSON.stringify(config, null, 2)}`, 'color:#0085fa', '--->>>', error);
      return Promise.reject(error);
    });
};

export default request;
