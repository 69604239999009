<template>
  <div id="app">
    <div class="layout">
      <Layout :style="{ height: 'auto' }">
        <!--菜单-->
        <Header class="page-header" :style="{ position: 'fixed', width: '100%' }">
          <global-menu></global-menu>
        </Header>
        <!--主体内容-->
        <Content class="k-content">
          <!--面包屑-->
          <global-breadcrumb class="k-breadcrumb"></global-breadcrumb>
          <global-grid></global-grid>
          <div style="padding: 0 40px">
            <router-view />
          </div>
        </Content>
        <!--尾部test-->
        <global-footer></global-footer>
      </Layout>
    </div>
  </div>
</template>

<script>
import GlobalMenu from '@/components/GlobalMenu/GlobalMenu';
import GlobalFooter from '@/components/GlobalFooter/index';
import GlobalBreadcrumb from '@/components/GlobalBreadcrumb';

import GlobalGrid from '@/components/GlobalGrid';

export default {
  data() {
    return {
      menus: [
        { text: '首页', to: '/', icon: 'ios-navigate', value: 'index' },
        { text: '关于我们', to: '/', icon: 'ios-navigate', value: 'index' },
        { text: '产品中心', to: '/', icon: 'ios-navigate', value: 'index' },
        { text: '联系我们', to: '/', icon: 'ios-navigate', value: 'index' },
      ],
    };
  },
  components: {
    GlobalMenu,
    GlobalFooter,
    GlobalBreadcrumb,
    GlobalGrid,
  },
  computed: {},
  created() {
    console.log(screen.width);
    this.handleScreenResize();
  },
  mounted() {
    window.onresize = () => {
      this.handleScreenResize();
    };
    // localStorage.setItem('locale', 'zh');
    // this.$i18n.locale = localStorage.getItem('locale');
  },
  methods: {
    handleScreenResize() {
      // const hash = window.location.hash;
      // if(screen.width<768){
      //   window.location.href=`http://localhost:9090${hash || ''}`; // 去移动端页面
      // }else{
      //   window.location.href=`http://localhost:9080${hash || ''}`; // 去pc端页面
      // }
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.layout {
  height: 100%;
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: auto;
  .page-header {
    position: fixed;
    width: 100%;
    z-index: 999;
  }
  .ivu-layout {
    height: 100%;
  }
}

.ivu-layout-content {
  overflow: auto;
}

.k-breadcrumb {
  margin: 20px auto 20px;
  max-width: 1200px;
  width: 100%;
}

.k-content {
  margin: 88px auto 20px;
  background: #fff;
  min-height: 500px;
  max-width: 1200px;
  width: 100%;
  height: auto;
}
.k-margin-bottom-20 {
  margin-bottom: 20px;
}
</style>
