import client from '../../../utils/request';

export function AddStar(params: any) {
  return client({
    method: 'post',
    url: '/api/system/addStar',
    data: params,
  });
}

export function GetList(params: { keyword?: string; pageNum: number; pageSize: number }) {
  return client({
    method: 'get',
    url: '/sysApi/pms/list',
    params,
  });
}

export function GetAllList() {
  return client({
    method: 'get',
    url: '/sysApi/pms/listAll',
  });
}
