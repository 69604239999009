<template>
  <Footer class="layout-footer-center">
    <div>
      <Row class="company-info" :gutter="16">
        <Col :xs="24" :sm="24" :md="24" :lg="8" span="8">
          <div><Icon size="20" type="ios-call" />电话：13865300917</div>
        </Col>
        <Col :xs="24" :sm="24" :md="24" :lg="8" span="8">
          <div><Icon size="20" type="ios-mail" />邮箱：ningguokemei@163com</div>
        </Col>
        <Col :xs="24" :sm="24" :md="24" :lg="8" span="8">
          <div><Icon size="20" type="ios-navigate" />地址：宁国市经济技术开发区众益广场8幢101号</div>
        </Col>
      </Row>
      <div>
        <div class="copy-right">{{ copyRightDate }} &copy; 宁国市柯美自动化设备制造有限公司</div>
        <br />
        联系电话：0563-4301737
      </div>
    </div>
  </Footer>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      copyRightDate: `2008-${new Date().getFullYear()}`,
    };
  },
};
</script>

<style scoped lang="less">
.layout-footer-center {
  text-align: center;
  background: #515a6e;
  color: #fefefe;
  > div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .company-info {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .copy-right {
    margin: 20px 0 0 0;
  }
  .ivu-icon {
    margin-right: 5px;
  }
}
</style>
