<template>
  <Carousel autoplay :height="300" :autoplay-speed="3000" v-model="activeIndex" loop>
    <CarouselItem>
      <div class="demo-carousel">
        <img class="k-carousel-img" src="http://minio.arthaskj.cn:6503/dev/home.jpg" />
      </div>
    </CarouselItem>
    <CarouselItem>
      <div class="demo-carousel">
        <img class="k-carousel-img" src="http://minio.arthaskj.cn:6503/dev/about3.png" />
      </div>
    </CarouselItem>
    <CarouselItem>
      <div class="demo-carousel">
        <img class="k-carousel-img" src="http://minio.arthaskj.cn:6503/dev/contact.png" />
      </div>
    </CarouselItem>
    <CarouselItem>
      <div class="demo-carousel">
        <img class="k-carousel-img" src="http://minio.arthaskj.cn:6503/dev/prod.png" />
      </div>
    </CarouselItem>
  </Carousel>
</template>

<script>
export default {
  name: 'GlobalCarousel',
  data() {
    return {
      activeIndex: 0,
    };
  },
};
</script>

<style scoped lang="less">
.demo-carousel {
  width: 100%;
  height: 100%;
  text-align: center;
  img {
    margin: 0 auto;
  }
}
</style>
